main {
  width: 100%;
  margin: 0 0 0 0;
}

main button {
  margin-top: 20px;
  background-color: #2D8CFF;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #2681F2;
}

.row {
  display: flex;
}

.column {
  flex: 1;
  position: relative;
}

.discuss-date {
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 0 !important;
  color: #8c8c8c !important;
}

.gx-wall-user-info {
  margin-bottom: 6px;
}

.gx-align-items-center {
  -ms-align-items: center !important;
  align-items: center !important;
}

.gx-flex-nowrap {
  flex-wrap: nowrap !important;
}

.gx-media {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: flex-start;
  align-items: flex-start;
}

.ant-avatar {
  position: relative;
}

.gx-mr-3,
.gx-mx-3 {
  margin-right: 1rem !important;
}

.gx-mb-2,
.gx-my-2 {
  margin-bottom: .5rem !important;
}

.gx-size-40 {
  height: 40px !important;
  width: 40px !important;
  line-height: 40px !important;
}

.gx-media-body {
  flex: 1 1;
}

.ant-pagination-item-link {
  margin: 0;
}